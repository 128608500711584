import styles from "./Loading.module.css";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoadingProps {}

const DataLoading: React.FC<LoadingProps> = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "70vh" }}>
            <div className={styles.rightContainer}>
                <div className={styles.layout}>
                    <div className={styles.bgText}>
                        <span className={styles.loader} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataLoading;
