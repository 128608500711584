import axios from "axios";

import { handleError } from "../menuSettings/menuManagementApis";

// Function to read file types items
export const readFileTypesItems = async () => {
    try {
        const response = await axios.get(`/api/file-types`);
        let responseData = [];
        if (response.data.success) {
            responseData = response.data.file_types;
        }
        return responseData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read file types packages items
export const readFileTypesPackagesItems = async (id: number) => {
    try {
        const response = await axios.get(`/api/file-types/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read file types packages items
export const readUseCaseChunkingFileTypesDropdown = async (useCaseID: number) => {
    try {
        const response = await axios.get(`/api/get_filetype_from_usecase/${useCaseID}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};
// Function to read file types packages items
export const readUseCaseCleaningFileTypesDropdown = async (useCaseID: number) => {
    try {
        const response = await axios.get(`/api/get_filetype_from_usecase_cleaning/${useCaseID}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};
// Function to read file types packages items
export const readUseCaseExtractionFileTypesDropdown = async (useCaseID: number) => {
    try {
        const response = await axios.get(`/api/get_filetype_from_usecase_extraction/${useCaseID}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read chunk-strategies items
export const readChunkingStrategiesItems = async () => {
    try {
        const response = await axios.get(`/api/chunk-strategies-params`);
        let responseData = [];
        if (response.data.success) {
            responseData = response.data.chunk_strategies;
        }
        return responseData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read chunk strategies parameters items
export const readChunkingStrategiesParametersItems = async (id: number) => {
    try {
        const response = await axios.get(`/api/chunk-strategies/${id}/parameters`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read extraction strategies items
export const readExtractionStrategiesItems = async () => {
    try {
        const response = await axios.get(`/api/extraction-strategies`);
        let responseData = [];
        if (response.data.success) {
            responseData = response.data.extraction_strategies;
        }
        return responseData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read extraction strategies parameters items
export const readExtractionStrategiesParamItems = async (id: number) => {
    try {
        const response = await axios.get(`/api/extraction-strategies/${id}/parameters`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read extraction strategies items
export const readCleaningStrategiesItems = async () => {
    try {
        const response = await axios.get(`/api/cleaning-strategies`);
        let responseData = [];
        if (response.data.success) {
            responseData = response.data.cleaning_strategies;
        }
        return responseData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read file types parameters items
export const readCleaningStrategiesPackagesItems = async (id: number) => {
    try {
        const response = await axios.get(`/api/cleaning-strategies/${id}/parameters`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read llm models
export const readLlmModelItems = async () => {
    try {
        const response = await axios.get(`/api/llm-params/get-drop-down`);
        let responseData = [];
        if (response.data.success) {
            responseData = response.data.llm_models;
        }
        return responseData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read indexes name
export const readIndexesName = async () => {
    try {
        const response = await axios.get(`/api/index-setup/index-dropdown`);
        let responseData = [];
        if (response.data.success) {
            responseData = response.data.usecase_indexs;
        }
        return responseData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read Chunk Strategy name
export const getChunkStrategyDropdownValues = async (fileTypeID: number) => {
    try {
        const response = await axios.get(`/api/chunk-strategy/dropdown/${fileTypeID}`);
        let chunkStrategiesDropdownData = [];
        if (response.data.success) {
            chunkStrategiesDropdownData = response.data.chunk_strategies;
        }
        return chunkStrategiesDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

export const getExtractionStrategyDropdownValuesByFileType = async (fileTypeID: number) => {
    try {
        const response = await axios.get(`/api/extraction-strategy/dropdown/${fileTypeID}`);
        let extractionStrategiesDropdownData = [];
        if (response.data.success) {
            // extractionStrategiesDropdownData = response.data.extraction_strategies;
            extractionStrategiesDropdownData = response.data.Extraction_strategies;
        }
        return extractionStrategiesDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

export const getCleaningStrategyDropdownValuesByFileType = async (fileTypeID: number) => {
    try {
        const response = await axios.get(`/api/cleaning-strategy/dropdown/${fileTypeID}`);
        // const response = await axios.get(`/api/cleaning-strategies/dropdown/${fileTypeID}`);
        let cleaningStrategiesDropdownData = [];
        if (response.data.success) {
            cleaningStrategiesDropdownData = response.data.cleaning_strategies;
            // cleaningStrategiesDropdownData = response.data.Cleaning_Strategy;
        }
        return cleaningStrategiesDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

export const getChunkStrategyDropdownValuesBYFileType = async (fileTypeID: number) => {
    try {
        const response = await axios.get(`/api/chunk-strategy/dropdown/${fileTypeID}`);
        let chunkStrategiesDropdownData = [];
        if (response.data.success) {
            chunkStrategiesDropdownData = response.data.chunk_strategies;
        }
        return chunkStrategiesDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

export const getFileNameDropdownValuesByUseCaseAndFileType = async (useCase: number, fileType: number) => {
    try {
        const response = await axios.get(`/api/usecase/${useCase}/files/${fileType}`);
        let fileNameDropdownData = [];
        if (response.data.success) {
            fileNameDropdownData = response.data.file_names;
        }
        return fileNameDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

export const getParamsDropdownValuesByChunkingStrategy = async (ChunkingStrategyID: number) => {
    try {
        const response = await axios.get(`/api/file_strategy_details/parmas/${ChunkingStrategyID}`);
        let paramsDropdownData = [];
        if (response.data.success) {
            paramsDropdownData = response.data.param_values;
        }
        return paramsDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};
export const getParamsDropdownValuesByDeploymentName = async (DeploymentName: string) => {
    try {
        const response = await axios.get(`/api/llm-params/${DeploymentName}`);
        let paramsDropdownData = {};
        if (response.data.success) {
            paramsDropdownData = response.data.data;
        }
        return paramsDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
    }
};
export const getFileTypeDropdownValuesByModalityChunkStrategy = async (useCaseID: number) => {
    let modalityFileTypesDropdownData = [];
    try {
        const response = await axios.get(`/api/file-type-chunk-strategy/get-drop-down/${useCaseID}`);
        if (response.data.success) {
            modalityFileTypesDropdownData = response.data.file_types;
        }
        return modalityFileTypesDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
        return modalityFileTypesDropdownData
    }
};
export const getFileTypesExcludingModalityChunkingStrategy = async (useCaseID: number) => {
    let detailsFileTypesDropdownData = [];
    try {
        const response = await axios.get(`/api/admin/file-type-packages-8/${useCaseID}`);
        if (response.data.success) {
            detailsFileTypesDropdownData = response.data.file_types;
        }
        return detailsFileTypesDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
        return detailsFileTypesDropdownData
    }
};

export const getChunkingStrategyExcludingModalityByFileType = async (fileTypeID: number) => {
    let detailsChunkingStrategyDropdownData = [];
    try {
        const response = await axios.get(`/api/chunk-strategy-without-modality/dropdown/${fileTypeID}`);
        if (response.data.success) {
            detailsChunkingStrategyDropdownData = response.data.chunk_strategies;
        }
        return detailsChunkingStrategyDropdownData; // Return the response data
    } catch (error) {
        handleError(error);
        return detailsChunkingStrategyDropdownData
    }
};
