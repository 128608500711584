import axios, { AxiosError, isAxiosError } from "axios";

import { MENU_MANAGEMENT_API_URL } from "../../../constants";

// Define the base URL for the API

// Function to insert a new menu item
export const insertMenuItem = async (menuData: {
    MenuItemName: string;
    ParentMenuItemID: number;
    Path: string;
    Description: string;
    IsActive: boolean;
    RoleID: number | string;
}) => {
    try {
        const response = await axios.post(MENU_MANAGEMENT_API_URL, {
            action: "insert",
            data: menuData
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a menu item
export const updateMenuItem = async (menuData: {
    MenuItemName: string;
    ParentMenuItemID: number;
    Path: string;
    Description: string;
    IsActive: boolean;
    RoleID: number | string;
}) => {
    try {
        const response = await axios.post(MENU_MANAGEMENT_API_URL, {
            action: "update",
            data: menuData
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read menu items
export const readMenuItems = async () => {
    try {
        const response = await axios.post(MENU_MANAGEMENT_API_URL, {
            action: "read"
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a menu item
export const deleteMenuItems = async (roleId: number | string) => {
    try {
        const response = await axios.post(MENU_MANAGEMENT_API_URL, {
            action: "delete",
            data: { MenuItemName: roleId }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Error handling function
// export const handleError = (error: any) => {
//     if (isAxiosError(error)) {
//         // Handle Axios errors
//         console.error("Axios error:", error.response?.data || error.message);
//         // alert(`Error: ${error.response?.data?.message || error.message}`);

//     } else {
//         // Handle other errors
//         console.error("Unexpected error:", error);
//         // alert("An unexpected error occurred. Please try again later.");
//     }
//     throw error;
// };

// Error handling function
export const handleError = (error: any): { message: string; type: string } => {
    let errorMessage: string;
    let errorType: string = "error";

    if (isAxiosError(error)) {
        // Handle Axios errors
        const axiosError = error as AxiosError;
        errorMessage = (axiosError?.response?.data as any)?.message || axiosError?.message || "An error occurred while processing the request.";
        console.error("Axios error:", axiosError.response?.data || axiosError.message);
    } else {
        // Handle other errors
        errorMessage = error?.message || "An unexpected error occurred. Please try again later.";
        console.error("Unexpected error:", error);
    }

    // Optionally log or notify for debugging
    // alert(`Error: ${errorMessage}`);

    // Throw the error object with a structured format for catch block
    throw { message: errorMessage, type: errorType };
};