import { FontSizes, FontWeights, type IChoiceGroupOption } from "@fluentui/react";

export const CHAT_APPROACHES = {
    PureReadRetrieveRead: "prrr",
    PureOReadRetrieveRead: "orrr",
    VisionReadRetriveRead: "vrrr",
    RetrieveThenRead: "rtr",
    ReadRetrieveRead: "rrr",
    useCaseReadRetrieveRead: "crrr",
    ReadRetrieveReadNew: "rrr_new",
    ReadDecomposeAsk: "rda",
    dalleReadRetrieveRead: "drrr",
    theNewApproach: "new"
};

// Array of route patterns to check against
export const ROUTE_FOR_PDF_BUTTON: string[] = ["/chat", "/usecase/geachat", "/bing", "/purepluso", "/pureplus", "/pure", "/usecase", "/qa", "/ask", "/talk"];
export const ROUTE_FOR_ADVANCE_SETTING_PANEL: string[] = ["/chat", "/usecase/geachat", "/bing", "/usecase", "/qa", "/ask", "/talk"];

export const ACCEPTED_FILE_UPLOAD_FORMATE_FOR_TALK = [
    ".csv",
    ".docx",
    ".pdf",
    ".pptx",
    ".txt",
    ".xlsx",
    ".c",
    ".cpp",
    ".html",
    ".java",
    ".json",
    ".md",
    ".php",
    ".py",
    ".rb",
    ".tex",
    ".css",
    ".js",
    ".ts",
    ".xml"
];

// Path & chat mode used for filtering constants
export const GEACHAT_ROUTE = "chat";
export const GEACHAT_API_PATH = "chat";
export const GEACHAT_CHAT_MODE = "GEAGPT Chat";
export const BING_ROUTE = "bing";
export const BING_API_PATH = "bing";
export const BING_CHAT_MODE = "Bing";
export const PURE_ROUTE = "pure";
export const PURE_API_PATH = "pure";
export const PURE_CHAT_MODE = "Pure";
export const PURE_PLUS_ROUTE = "pureplus";
export const PURE_PLUS_API_PATH = "pureplus";
export const PURE_PLUS_CHAT_MODE = "Pure Plus";
export const PURE_PLUS_O_ROUTE = "purepluso";
export const PURE_PLUS_O_API_PATH = "purepluso";
export const PURE_PLUS_O_CHAT_MODE = "GPT 4o";
export const USE_CASE_INITIAL_ROUTE = "usecase";
export const USE_CASE_INITIAL_API_PATH = "usecase";
export const USE_CASE_INITIAL_CHAT_MODE = "Usecase ";
export const ASK_QUESTION_ROUTE = "qa";
export const ASK_QUESTION_API_PATH = "qa";
export const ASK_QUESTION_CHAT_MODE = "GEAGPT Ask";
export const ASK_ROUTE = "ask";
export const ASK_API_PATH = "ask";
export const ASK_CHAT_MODE = "Ask";
export const TALK_TO_DOC_ROUTE = "talk";
export const TALK_TO_DOC_API_PATH = "talk";
export const TALK_TO_DOC_CHAT_MODE = "Talk";
export const BRAZIL_TAX_CODE_ROUTE = "braziltaxcode";
export const BRAZIL_TAX_CODE_API_PATH = "braziltaxcode";
export const DALL_ROUTE = "dalle";
export const DALL_API_PATH = "dalle";
export const DALL3_ROUTE = "dalle3";
export const DALL3_API_PATH = "dalle3";
export const RAG_GROUND_TRUTH_ROUTE = "ground_truth";
export const RAG_GROUND_TRUTH_API_PATH = "ground_truth";
export const RAG_EVALUATION_ROUTE = "evaluation";
export const RAG_EVALUATION_API_PATH = "evaluation";
export const RAG_RESULTS__ROUTE = "result";
export const RAG_RESULTS__API_PATH = "result";
export const RAG_Feedback_ROUTE = "feedback";
export const RAG_Feedback_API_PATH = "feedback";
export const BUSINESS_ROUTE = "business_user";
export const BUSINESS_API_PATH = "business_user";
export const ABOUT_ROUTE = "about";
export const ABOUT_AI_PATH = "about";
export const HELP_ROUTE = "help";
export const HELP_API_PATH = "help";
export const LOGIN_ROUTE = "login";
export const LOGIN_API_PATH = "login";
export const LOGOUT_ROUTE = "logout";
export const LOGOUT_API_PATH = "logout";
export const REMOVE_INSTANCE_API_PATH = "remove_instance";
export const PROCESS_DOCS_API_PATH = "process_docs";
// export const logout_API_PATH = "logout";

export const USE_CASE_NAME_PARAM = "name";
export const CHAT_SESSION_ID_PARAM = "chat_session_id";
export const RAG_ID_PARAM = "ragId";

// Advance setting Panel constants
export const HEADER_TEXT = "Configure answer generation";
export const PROMPT_TEMPLATE_LABEL = "Override prompt template";
export const PROMPT_ARIA_LABEL = "Add instructions for the response generation. This will work as System Prompt for LLM Agents";
export const EXCLUDE_CATEGORY_LABEL = "Exclude category";
export const EXCLUDE_CATEGORY_ARIA_LABEL = "Category texts to be excluded from the generated response.";
export const RETRIEVE_DOCUMENT_BUTTON_LABEL = "Override prompt template";
export const RETRIEVE_DOCUMENT_BUTTON_TOOLTIP_CONTENT = "Number of documents to refer for generating response.";
export const RETRIEVE_DOCUMENT_INCREMENT_BUTTON_ARIA_LABEL = "Increase value by 1";
export const RETRIEVE_DOCUMENT_DECREMENT_BUTTON_ARIA_LABEL = "Decrease value by 1";
export const MAX_TOKEN_BUTTON_LABEL = "Max Tokens: ";
export const MAX_TOKEN_BUTTON_TOOLTIP_CONTENT = "Limits how many tokens or words will be generated in the output text completion.";
export const USE_SEMANTIC_RANKER_CHECKBOX_LABEL = "Use semantic ranker for retrieval";
export const USE_SEMANTIC_CAPTIONS_CHECKBOX_LABEL = "Use query-contextual summaries instead of whole documents";
export const USE_SUGGEST_FOLLOWUP_QUESTIONS_CHECKBOX_LABEL = "Suggest follow-up questions";
export const CUSTOM_SLIDER_TEMPERATURE_LABEL = "Temperature";
export const CUSTOM_SLIDER_TEMPERATURE_TOOLTIP_CONTENT =
    "Controls creativity vs accuracy, with higher values resulting in more surprising but potentially nonsensical text.";
export const PROMPT_TEMPLATE_PREFIX_LABEL = "Override prompt prefix template";
export const PROMPT_TEMPLATE_SUFFIX_LABEL = "Override prompt suffix template";
export const ADDITION_ICON_NAME = "CalculatorAddition";
export const SUBTRACT_ICON_NAME = "CalculatorSubtract";

export const QUESTION_INPUT_PLACE_HOLDER_LABEL = "Type a new question (e.g. How do I find information? )";
export const ONE_SHOT_QUESTION_INPUT_PLACEHOLDER = "Example: How do I find information?";
export const DALLE_QUESTION_INPUT_PLACEHOLDER = "An Impressionist oil painting of sunflowers in a purple vase…";

export const DALLE_DOWNLOAD_FILE_NAME = "AI_Image.png";

// Choose a conversation style
export const WRAPPER_TITLE = "Choose a conversation style";
export const CONVERSATION_STYLES = [
    { btnLabel: "Creative", btnSubLabel: "More" },
    { btnLabel: "Balanced", btnSubLabel: "More" },
    { btnLabel: "Precise", btnSubLabel: "More" }
];

/**
 * Function to handle the change event of the approach choice group.
 */
export const APPROACHES: IChoiceGroupOption[] = [
    {
        key: CHAT_APPROACHES.RetrieveThenRead,
        text: "Retrieve-Then-Read"
    },
    {
        key: CHAT_APPROACHES.ReadRetrieveRead,
        text: "Read-Retrieve-Read"
    },
    {
        key: CHAT_APPROACHES.ReadDecomposeAsk,
        text: "Read-Decompose-Ask"
    }
];

// response api messages
export const ON_THUMB_CLICK_SUCCESS_MESSAGE = "Document updated successfully";
export const API_SERVER_ERROR_MESSAGE = "Could not generate response. Please try again.";
export const API_UNAUTHORIZED_ERROR_MESSAGE = "Unauthorized";
export const API_LOGIN_ERROR_MESSAGE = "Please login to continue.";
export const API_CLIENT_SIDE_ERROR_MESSAGE = " Please try again.";

export const CUSTOM_INCREMENT_DECREMENT_BUTTON_STYLES = { color: "#000", fontSize: "10px" };

export type ExampleModel = {
    text: string;
    value: string;
    type?: string;
    tip?: string;
    link?: boolean;
    image?: string;
};

export const MAX_TOKENS = 128000;
export const GPT4_TURBO_MAX_TOKENS = 80000;
export const DALLE3_MAX_TOKENS = 4000;
export const USER_INACTIVE_TIMEOUT = 1000 * 60 * 55;

// Regex to check if URL starts with http, https, or www, or contains a file extension
export const EXTERNAL_LINK_REGEX =
    /\b(?:https?:\/\/|www\.)\S+\.(?:[a-zA-Z]{2,})(?<!\.(?:pdf|pptx?|txt|docx?|xml|csv|json|zip|rar|tar\.gz|7z))\b|\b(?:[a-zA-Z0-9.-]+\.(?:[a-zA-Z]{2,}))(?<!\.(?:pdf|pptx?|txt|docx?|xml|csv|json|zip|rar|tar\.gz|7z))\b/g;

// List of common file extensions
export const FILE_EXTENSIONS =
    "pdf|docx?|xlsx?|pptx?|txt|rtf|csv|tsv|json|xml|css|jsx?|tsx?|jpg|jpeg|png|gif|bmp|svg|mp3|mp4|wav|avi|mov|wmv|flv|zip|rar|7z|tar|gz";

export const FUNCTIONALITY_OPTIONS = [
    { key: "1", text: "Chunking Strategy" },
    { key: "2", text: "Vector Store" },
    { key: "3", text: "Embedding Model" },
    { key: "4", text: "RAG Method" },
    { key: "5", text: "System Prompt" },
    { key: "6", text: "LLM Method" },
    { key: "7", text: "Updated Source Data" }
];

export const LOCAL_BASE_URL = "http://localhost:5000";

export const DOMAIN_NAMES = [
    ".com",
    ".org",
    ".net",
    ".edu",
    ".gov",
    ".io",
    ".co",
    ".us",
    ".uk",
    ".ca",
    ".de",
    ".jp",
    ".fr",
    ".au",
    ".ru",
    ".ch",
    ".it",
    ".nl",
    ".se",
    ".no",
    ".es",
    ".mil",
    ".biz",
    ".info",
    ".mobi",
    ".name",
    ".pro",
    ".tv",
    ".asia",
    ".me",
    ".cn",
    ".in",
    ".mx",
    ".br",
    ".za",
    ".kr",
    ".eu",
    ".ar",
    ".pl",
    ".tr",
    ".gr",
    ".pt",
    ".dk",
    ".fi",
    ".ie",
    ".nz",
    ".sg",
    ".hk",
    ".il",
    ".my",
    ".ae",
    ".sa",
    ".cl",
    ".th",
    ".vn",
    ".tw",
    ".id",
    ".pk",
    ".ph"
];

export const VISION_FILE_MAX_SIZE = 1.4 * 1024 * 1024; // Image converted into base64 will increase the file size by .33% (approximately 2 MB)

// Welcome Notes
export const GPT4o_MINI_WELCOME_NOTES = "Welcome to GEA's dedicated GPT4o mini experience.";
export const BING_WELCOME_NOTES = "GPT-4o + Bing Search is your AI Powered Co-Pilot for the Web";
export const BRAZILIAN_TAX_WELCOME_NOTES = "Brazilian Tax jurisdiction";
export const DALL_E3_WELCOME_NOTES = "Welcome to GEA's dedicated AI image generation experience with Dall-E.";
export const HELP_WELCOME_NOTES = "Help Articles & FAQ's";
export const ONE_SHOT_WELCOME_NOTES = "Experiment using One Shot Techniques with BetterBot AI.";
export const GPT4o_WELCOME_NOTES = "Welcome to GEA's dedicated GPT-4o experience.";
export const DOCU_SPEAK_WELCOME_NOTES = "Use Generative AI to analyze your file(s) using GPT4o";

// Define the settings base URL for the APIs
export const USER_ROLE_SETTING_API_URL = "/api/userRole";
export const ROLE_MENU_MANAGEMENT_API_URL = "/api/roleMenu";
export const MENU_MANAGEMENT_API_URL = "/api/menuSettings";
// export const CONFIGURATION_AND_SETTINGS_API_URL = "/api/menuSettings";
export const ICON_STYLES = { root: { width: 15, height: 15, margin: "0px 5px", padding: "0px 5px" } };
export const ICON_PROP_STYLES = { root: { color: "#0303b8" } };
export const GRID_INLINE_2 = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "10px 30px",
    maxWidth: "800px",
    margin: "5px auto",
    justifyContent: "center"
};
export const GRID_INLINE_3 = { display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px 30px", justifyContent: "center" };
export const SECTION_LABEL = {
    color: "#0303b8",
    fontSize: "18px",
    fontWeight: "600",
    borderBottom: "1px solid #0303b8",
    height: "30px",
    maxWidth: "800px",
    margin: "8px 0"
};

export const INPUT_WIDTH = { root: { width: "calc((100vw - 400px)/2 )", maxWidth: "360px" } };
export const DROPDOWN_WRAPPER_HEIGHT_CUSTOM_STYLE = { maxHeight: "250px", overflowY: "auto", minWidth:"220px" };
//vectorStore

export const STRING_VALIDATION_REGEX = /^(?!.*\b(SELECT|INSERT|DELETE|UPDATE|DROP|CREATE|ALTER|EXEC|UNION)\b).*[-a-zA-Z0-9 .,_@#$%^&*()+={}[\]]*$/i;
export const FILE_NAME_REGEX = /^[a-zA-Z0-9 ._-]*$/;
export const FILE_TYPE_REGEX = /^[a-zA-Z0-9.]*$/;
export const MODEL_VERSION_REGEX = /^[a-zA-Z0-9. _-]*$/;
export const ANALYZER_VALIDATION_REGEX = /^[a-zA-Z0-9 .,_-]*$/;

export const USE_CASE_CONTEXT_FILE_UPLOAD_FORMATE =
    ".csv, .docx, .pdf, .pptx, .txt, .xlsx, .c, .cpp, .html, .java, .json, .md, .php, .py, .rb, .tex, .css, .js, .ts, .xml";
