import { createContext, useContext } from "react";

import { DropdownItem } from "./api";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SettingContextValue {
    selectedUseCase: DropdownItem;
    setSelectedUseCase: React.Dispatch<React.SetStateAction<DropdownItem>>;
    useCaseOptions: DropdownItem;
    setUseCaseOptions: React.Dispatch<React.SetStateAction<DropdownItem[]>>;
    selectedMetrics: DropdownItem[];
    setSelectedMetrics: React.Dispatch<React.SetStateAction<DropdownItem[]>>;
}

// SettingContext is a context that provides the selected menu item, a function to set the selected menu item, a function to generate a PDF, a reference to the target element, and a flag to indicate whether the answers are being displayed.
export const SettingContext = createContext<any | null>(null);

// useSettingContext is a custom hook that returns the SettingContext value.
export const useSettingContext = () => useContext(SettingContext);
