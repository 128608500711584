import React, { useRef, useState } from 'react';

import  generatePDF, {Resolution, Margin, usePDF as originalToPdf } from 'react-to-pdf';
import PDFDownloadLink from 'react-to-pdf';

// Re-export everything from react-to-pdf
export { PDFDownloadLink, Margin, Resolution, generatePDF };

export const usePDF = (options?: any) => {
  const targetRef = useRef<HTMLElement>(null);

  const toPDF = async () => {
    if (!targetRef.current) return;

    // // Store the original width
    // const originalWidthValue = targetRef.current.style.width;

    // Store original styles
    const originalStyles = {
        width: targetRef.current.style.width,
        height: targetRef.current.style.height,
        position: targetRef.current.style.position,
        overflow: targetRef.current.style.overflow,
        maxHeight: targetRef.current.style.maxHeight
      };

    // // Set the width to at least 1024px
    // targetRef.current.style.minWidth = '1024px';

    try {
        // Apply styles to capture full content
        targetRef.current.style.width = '1024px';  // or your desired width
        targetRef.current.style.height = 'auto';
        targetRef.current.style.position = 'relative';
        targetRef.current.style.overflow = 'visible';
        targetRef.current.style.maxHeight = 'none';  

        // Wait for the DOM to update
        await new Promise((resolve) => setTimeout(resolve, 100));

        // Call the original toPdf function
        // await originalToPdf(options);

        // Generate PDF using the imported generatePDF function
        await generatePDF(targetRef, {
            ...options,
            page: {
              ...options?.page,
              // Ensure the whole content fits
              scale: 1,
              // You might need to adjust these based on your content
              width: 1024,
              height: undefined // Let it auto-adjust to content height
            }
        });

    } finally {
        // Restore original styles
      if (targetRef.current) {
        targetRef.current.style.width = originalStyles.width;
        targetRef.current.style.height = originalStyles.height;
        targetRef.current.style.position = originalStyles.position;
        targetRef.current.style.overflow = originalStyles.overflow;
        targetRef.current.style.maxHeight = originalStyles.maxHeight;
      }
    }
  };

  return { toPDF, targetRef };
};
