import Cookies from "js-cookie";
import React, { Suspense, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";
import "./utils/fetchInterceptor";
const Layout = React.lazy(() => import("./pages/layout/Layout"));
const NoPage = React.lazy(() => import("./pages/NoPage"));
const OneShot = React.lazy(() => import("./pages/oneshot/OneShot"));
const Chat = React.lazy(() => import("./pages/chat/Chat"));
const GPT4oMini = React.lazy(() => import("./pages/gpt4oMini/GPT4oMini"));
const Dalle3 = React.lazy(() => import("./pages/dalle3/Dalle3"));
const Login = React.lazy(() => import("./pages/login/Login"));
const UsecasePage = React.lazy(() => import("./pages/usecase/Usecase"));
const TalkPage = React.lazy(() => import("./pages/talk/Talk"));
const Bing = React.lazy(() => import("./pages/Bing/Bing"));
const HelpPage = React.lazy(() => import("./pages/HelpFAQ/HelpFAQ"));
const DalleLayout = React.lazy(() => import("./pages/DalleLayout/DalleLayout"));
const BetterBotAIAbout = React.lazy(() => import("./pages/BetterBotAIAbout/BetterBotAIAbout"));
const View = React.lazy(() => import("./pages/Ragas/View"));
const Evaluation = React.lazy(() => import("./pages/Ragas/Evaluation"));
const RagasSummaryTable = React.lazy(() => import("./pages/Ragas/RagasSummaryTable"));
const EvaluationFeedback = React.lazy(() => import("./pages/Ragas/EvaluationFeedback"));
const RagasLayout = React.lazy(() => import("./pages/Ragas/RagasLayout"));
const PurePlusO = React.lazy(() => import("./pages/purepluso/PurePlusO"));
const Unauthorized = React.lazy(() => import("./pages/Unauthorized/Unauthorized"));
import { MenuProvider } from "./MenuProvider";
import { RagasProvider } from "./RagasProvider";
const MenuSettings = React.lazy(() => import("./pages/setting/menuSettings/MenuSettings"));
const FileProcessingConfigLandingPage = React.lazy(() => import("./pages/setting/aiConfigSettings/AIConfigLandingPage"));
const UseCase = React.lazy(() => import("./pages/setting/useCase/UseCaseLandingPage"));
const AgentToolLandingPage = React.lazy(() => import("./pages/setting/agentsToolSettings/AgentToolLandingPage"));
const UserRoles = React.lazy(() => import("./pages/setting/userRoleSettings/UserRoles"));
const SettingsLayout = React.lazy(() => import("./pages/setting/SettingsLayout"));
const UseCaseAPI = React.lazy(() => import("./pages/setting/apiSettings/UseCaseAPI"));
const Loading = React.lazy(() => import("./components/Loading/Loading"));
const AfterLoginLoading = React.lazy(() => import("./components/Loading/AfterLoginLoading"));
import { ProtectedRoute } from "./pages/setting/routeProtection/ProtectedRoute";
import { DeveloperProtectedRoute } from "./pages/setting/routeProtection/DeveloperProtectedRoute";
import { SettingProvider } from "./SettingProvider";
import { useMenuContext } from "./MenuContext";
import DataLoading from "./components/Loading/DataLoading";

initializeIcons("https://res.cdn.office.net/files/fabric-cdn-prod_20241209.001/assets/icons/");

/**
 * The main application component.
 * It uses HashRouter for routing and MenuProvider for menu context.
 * It defines routes for various components like Chat, Bing, Pure, UsecasePage, OneShot, GPT4oMini, TalkPage, etc.
 * Each route is associated with a path and an element which is the component to be rendered when the path is matched.
 * Some routes also include parameters in their paths like chat_session_id and name.
 * There are also routes for login and logout.
 * If a path does not match any of the defined routes, the NoPage component is rendered.
 *
 * @returns {JSX.Element} The rendered component tree for the application.
 */
export default function App() {
    const isUserLoggedIn = Cookies.get("isUserLoggedIn");
    const [userRole, setUserRole] = useState<string>(""); //All // Change this to simulate different roles
    const { rootPath, isMenuLoading } = useMenuContext() || {};
    if (isMenuLoading) return <DataLoading />;
    return (
        <HashRouter>
            <MenuProvider>
                <RagasProvider>
                    <SettingProvider>
                        <Suspense fallback={<Loading />}>
                            <Routes>
                                <Route
                                    index
                                    element={
                                        <Suspense fallback={<Loading />}>
                                            <Navigate to={`${isUserLoggedIn == "True" ? (rootPath ? rootPath : "/loading") : "/login"}`} replace />
                                        </Suspense>
                                    }
                                    errorElement={<Login />}
                                />
                                <Route
                                    path="/"
                                    element={
                                        <Suspense fallback={<AfterLoginLoading />}>
                                            <Layout />
                                        </Suspense>
                                    }
                                >
                                    <Route path="/bing/:chat_session_id" element={<Bing />} />
                                    <Route path="/bing" element={<Bing />} />
                                    <Route path="/usecase/:name" element={<UsecasePage />} />
                                    <Route path="/usecase/:name/:chat_session_id" element={<UsecasePage />} />
                                    <Route path="/qa" element={<OneShot />} />
                                    <Route path="/ask" element={<OneShot />} />
                                    <Route path="/ask/:chat_session_id" element={<OneShot />} />
                                    <Route path="/qa/:chat_session_id" element={<OneShot />} />
                                    <Route path="/pureplus" element={<GPT4oMini />} />
                                    <Route path="/pureplus/:chat_session_id" element={<GPT4oMini />} />
                                    <Route path="/purepluso" element={<PurePlusO />} />
                                    <Route path="/purepluso/:chat_session_id" element={<PurePlusO />} />
                                    <Route path="/talk/:chat_session_id" element={<TalkPage />} />
                                    <Route path="/talk" element={<TalkPage />} />
                                </Route>
                                <Route
                                    path="/"
                                    element={
                                        <Suspense fallback={<AfterLoginLoading />}>
                                            <DalleLayout />
                                        </Suspense>
                                    }
                                >
                                    <Route path="/dalle3" element={<Dalle3 />} />
                                    <Route element={<RagasLayout />}>
                                        <Route path="/ground_truth" element={<View />} />
                                        <Route path="/evaluation" element={<View />} />
                                        <Route path="/result" element={<RagasSummaryTable />} />
                                        <Route path="/result/:ragId" element={<Evaluation />} />
                                        <Route path="/feedback" element={<RagasSummaryTable />} />
                                        <Route path="/feedback/:ragId" element={<EvaluationFeedback />} />
                                    </Route>
                                    <Route path="/about" element={<BetterBotAIAbout />} />
                                    <Route path="/settings" element={<SettingsLayout userRole={userRole} />} />
                                    <Route path="/help" element={<HelpPage />} />
                                </Route>
                                <Route path="/" element={<SettingsLayout userRole={userRole} />}>
                                    <Route
                                        path="/menu-settings"
                                        element={<ProtectedRoute element={<MenuSettings />} userRole={userRole} allowedRoles={["Admin"]} />}
                                    />
                                    <Route
                                        path="/user-roles"
                                        element={<ProtectedRoute element={<UserRoles />} userRole={userRole} allowedRoles={["Admin"]} />}
                                    />
                                    <Route path="/api" element={<ProtectedRoute element={<UseCaseAPI />} userRole={userRole} allowedRoles={["Admin"]} />} />
                                    <Route path="/ai-configs" element={<DeveloperProtectedRoute element={<FileProcessingConfigLandingPage />} />} />
                                    <Route path="/use-case" element={<DeveloperProtectedRoute element={<UseCase />} />} />
                                    <Route path="/agents-tool-settings" element={<DeveloperProtectedRoute element={<AgentToolLandingPage />} />} />
                                </Route>
                                <Route path="/login" element={<Login />} />
                                <Route path="/403" element={<Unauthorized />} />
                                <Route path="/loading" element={<DataLoading />} />
                                <Route path="*" element={<NoPage />} />
                            </Routes>
                        </Suspense>
                    </SettingProvider>
                </RagasProvider>
            </MenuProvider>
        </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
);
