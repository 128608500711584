import axios from "axios";

import { handleError } from "../../menuSettings/menuManagementApis";
import { ChunkingParams } from "./UseCaseDataPreprocessChunkStrategy";
import { CleaningParams } from "./UseCaseDataPreprocessCleaningStrategy";
import { UseCaseDataPreprocessFileStrategyDetail } from "./UseCaseDataPreprocessFileStrategyDetail";
import { UseCaseDataPreprocessFileStrategy } from "./UseCaseDataPreprocessFileStrategy";
import { ExtractionParams } from "./UseCaseDataPreprocessExtractionStrategy";
import { urlData } from "./URL";
import { UseCaseDataPreprocessChunkStrategyModality } from "./UseCaseDataPreprocessChunkStrategyModality";
import { ContextFiles } from "./ContextFiles";

// chunk-strategies APIs
// Function to read chunk strategies items
export const readChunkStrategies = async (useCaseId: number) => {
    try {
        const response = await axios.get(`/api/chunk-strategies/${useCaseId}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read chunk strategies param values items
export const readChunkStrategiesParamValues = async (useCaseId: number, fileTypeID: number, chunkStrategyID: number) => {
    try {
        const response = await axios.get(`/api/chunk-strategy/params-value/${useCaseId}/${fileTypeID}/${chunkStrategyID}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a chunk strategies item
export const updateChunkStrategies = async (formData: ChunkingParams[]) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.put(`/api/chunk-strategy/`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to insert a new chunk strategies item
export const insertChunkStrategies = async (formData: ChunkingParams[]) => {
    // delete formData?.ID;
    const stringifiedFormData = JSON.stringify(formData);
    try {
        const response = await axios.post(`/api/chunk-strategy`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a chunk strategies item
export const deleteChunkStrategies = async (id: number | string) => {
    try {
        const response = await axios.delete(`/api/chunk-strategy/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Cleaning Strategy APIs
// Function to read cleaning strategy items
export const readCleaningStrategy = async (id: number) => {
    try {
        const response = await axios.get(`/api/cleaning-strategies/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read cleaning-strategy  param values items
export const readCleaningStrategiesParamValues = async (useCaseId: number, fileTypeID: number, cleaningID: number) => {
    try {
        const response = await axios.get(`/api/cleaning-strategies/${useCaseId}/${fileTypeID}/${cleaningID}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to insert a new cleaning strategy item
export const insertCleaningStrategy = async (formData: CleaningParams[]) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.post(`/api/cleaning-strategy`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a cleaning strategy item
export const updateCleaningStrategy = async (formData: CleaningParams[], useCaseId: number, fileTypeID: string | number, cleaningID: string | number) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.put(`/api/cleaning-strategy/${useCaseId}/${fileTypeID}/${cleaningID}`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a cleaning strategy item
export const deleteCleaningStrategy = async (useCaseId: number, fileTypeID: string | number, cleaningID: string | number) => {
    try {
        const response = await axios.delete(`/api/cleaning-strategy/${useCaseId}/${fileTypeID}/${cleaningID}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

export const readExtractionStrategy = async (id: number) => {
    try {
        const response = await axios.get(`/api/extraction-strategy/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to insert a new Extraction strategy item
export const insertExtractionStrategy = async (formData: ExtractionParams[]) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.post(`/api/extraction-strategy`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a Extraction strategy item
export const updateExtractionStrategy = async (formData: ExtractionParams[], useCaseId: number, fileTypeID: string | number, extractionID: string | number) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.put(`/api/extraction-strategy/${useCaseId}/${extractionID}/${fileTypeID}`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a Extraction strategy item
export const deleteExtractionStrategy = async (useCaseId: number, fileTypeID: string | number, extractionID: string | number) => {
    try {
        const response = await axios.delete(`/api/extraction-strategy/${useCaseId}/${fileTypeID}/${extractionID}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// File Strategies APIs
// Function to read file strategies items
export const readFileStrategies = async (id: number) => {
    try {
        const response = await axios.get(`/api/file-strategy/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to insert a new file strategies item
export const insertFileStrategies = async (formData: UseCaseDataPreprocessFileStrategy) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.post(`/api/file-strategy`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a file strategies item
export const updateFileStrategies = async (formData: UseCaseDataPreprocessFileStrategy, id: number) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.put(`/api/file-strategy/${id}`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a file strategies item
export const deleteFileStrategies = async (id: number | string) => {
    try {
        const response = await axios.delete(`/api/file-strategy/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// FileStrategyDetails APIs
// Function to read file strategy details items
export const readFileStrategyDetails = async (id: number) => {
    try {
        const response = await axios.get(`/api/file-strategy-details/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to insert a new file strategy details item
export const insertFileStrategyDetails = async (formData: UseCaseDataPreprocessFileStrategyDetail) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.post(`/api/file-strategy-details`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a file strategy details item
export const updateFileStrategyDetails = async (formData: UseCaseDataPreprocessFileStrategyDetail, id: number) => {
    const stringifiedFormData = JSON.stringify(formData);

    try {
        const response = await axios.put(`/api/file-strategy-details/${id}`, stringifiedFormData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a file strategy details item
export const deleteFileStrategyDetails = async (id: number | string) => {
    try {
        const response = await axios.delete(`/api/file-strategy-details/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// URLs APIs
// Function to read urls items
export const readUrlsItems = async (id: number) => {
    try {
        const response = await axios.get(`/api/urls/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to insert a new urls item
export const insertUrlsItem = async (formData: urlData) => {
    try {
        const response = await axios.post(`/api/urls`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a urls item
export const updateUrlsItem = async (formData: urlData, id: number) => {
    try {
        const response = await axios.put(`/api/urls/${id}`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a urls item
export const deleteUrlsItems = async (id: number | string, URL: number | string, UseCaseID: number | string) => {
    try {
        const response = await axios.delete(`/api/urls/${id}/${UseCaseID}?file_name=${URL}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Chunk-Strategy-Modality APIs
// Function to read urls items
export const readChunkStrategyModalityDetailsItems = async (id: number) => {
    try {
        const response = await axios.get(`/api/chunk-strategy-modality-details/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to insert a new urls item
export const insertChunkStrategyModalityDetailsItem = async (formData: UseCaseDataPreprocessChunkStrategyModality) => {
    try {
        const response = await axios.post(`/api/chunk-strategy-modality-details`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a urls item
export const updateChunkStrategyModalityDetailsItem = async (formData: UseCaseDataPreprocessChunkStrategyModality, id: number) => {
    try {
        const response = await axios.put(`/api/chunk-strategy-modality-details/${id}`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a urls item
export const deleteChunkStrategyModalityDetailsItems = async (id: number | string) => {
    try {
        const response = await axios.delete(`/api/chunk-strategy-modality-details/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// ContextFile APIs
// Function to read contextFile items
export const readContextFileItems = async (usecase: string) => {
    try {
        const response = await axios.get(`/list-files/${usecase}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to insert a new contextFile item
export const insertContextFileItem = async (formData: FormData) => {
    try {
        const response = await axios.post(`/list-files/`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a contextFile item
export const updateContextFileItem = async (formData: { usecase_name: string; file_name: string; new_status: boolean }) => {
    try {
        const response = await axios.put(`/list-files/`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a contextFile item
export const deleteContextFileItems = async (usecase_name: number | string, file_name: string) => {
    try {
        const response = await axios.delete(`/list-files/`, {
            data: {
                usecase_name,
                file_name
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// IFRS data processing
// Function for 1 create container
export const createContainer = async (formData: any) => {
    try {
        const response = await axios.post(`/admin/process-usecase-container`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to 2 dataLakeToBlobStorageFileCopy
export const dataLakeToBlobStorageFileCopy = async (formData: any) => {
    try {
        const response = await axios.post(`/admin/listfileblobs`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to 3.Populate AI search Tables
export const populateAISearchTables = async (formData: any) => {
    try {
        const response = await axios.post(`/admin/copyadmintousecase`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to populate_file_strategy
export const populateFileStrategy = async (formData: any) => {
    try {
        const response = await axios.post(`/admin/populate_filestrategy`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to 5.create_index
export const createIndex = async (formData: any) => {
    try {
        const response = await axios.post(`/admin/create_index`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

export const processDataCopyingAndUseCaseIndexing = async (formData: any) => {
    try {
        const response = await axios.post(`/admin/process_usecase_indexing`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to 6.Create Files In Blob Split Folder Based On DetailStrategy Table
export const createFilesInBlobSplitFolderBasedOnDetailStrategyTable = async (
    formData: any,
    cb: ({ }: { message: string; type: "success" | "error"; time?: number }) => void
) => {
    try {
        const response = await axios.post(`/admin/split_and_copy_files_from_blob`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return { message: response?.data?.message, success: response?.status < 300 }; // Return the response data
    } catch (error) {
        handleError(error);
        cb({ message: error && (error as any)?.message || "An error occurred", type: "error" });
    }
};

// Function to 7.delete usecase
export const deleteUsecase = async (formData: any) => {
    try {
        const response = await axios.post(`/admin/delete_usecase_data`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to 7.delete usecase
export const executeDataProcessingPipeline = async (usecase: any) => {
    try {
        const response = await axios.post(`/admin/chunking_and_indexing`, {
            "usecase_name": usecase,
            "category": ""
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};
