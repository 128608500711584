// import { Navigate } from "react-router-dom";

// interface ProtectedRouteProps {
//     element: React.ReactElement;
//     userRole: string;
//     allowedRoles: string[];
// }

// export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, userRole, allowedRoles, ...rest }) => {
//     return allowedRoles.includes(userRole) ? element : <Navigate to="/403" />; // Redirect to home or an unauthorized page
// };

import React from "react";
import { Navigate } from "react-router-dom";

import { useMenuContext } from "../../../MenuContext";

interface ProtectedRouteProps {
    element: React.ReactElement; // Ensure element is defined as a React element
    userRole: string;
    allowedRoles: string[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, userRole, allowedRoles, ...rest }) => {
    const { isAdmin } = useMenuContext() || {};
    return allowedRoles.includes(userRole) || isAdmin ? element : <Navigate to="/403" />; // Redirect to home or an unauthorized page
};
