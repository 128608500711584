/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Cookies from "js-cookie";
import { mergeStyles } from "@fluentui/react";

import { handleLogout, stringifyReqBody } from "../api";
import { EXTERNAL_LINK_REGEX, FILE_EXTENSIONS, USER_INACTIVE_TIMEOUT } from "../constants";
import { readChunkingStrategiesParametersItems } from "../pages/setting/useCase/dropDownApis";
import { getDropdownOptions } from "../pages/setting/validationConfig";

const isLocalhost = window.location.host === "localhost:5173" || window.location.host === "127.0.0.1:5173";

/**
 * Get an array of unique property values from an array of objects.
 * @param data The array of objects to search.
 * @param key The property name to search for unique values.
 * @returns An array of unique property values of type K.
 */
export const getUniquePropertyValues = <K extends keyof any>(data: Record<K, any>[], key: K): K[] => {
    // Use a Set to store unique property values
    const uniqueValues = new Set<K>(); // Define the Set with the property type

    // Iterate over the data array and add the property value to the Set
    data.forEach(item => {
        uniqueValues.add(item[key]);
    });

    // Convert the Set back to an Array if you need to work with an array
    return Array.from(uniqueValues) || [];
};

export async function makeStreamingApiRequest(api, request, setIsLoading, setAnswers, setStreamEnd, setError, lastQuestion) {
    try {
        const response = await fetch(api, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/event-stream"
            },
            body: stringifyReqBody(request)
        });

        if (!response.ok) {
            setError(`Fail to fetch response`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        checkResStatus(response, setError);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let rawData = "";

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                setStreamEnd(true);
                break;
            }
            rawData += decoder.decode(value, { stream: true });
            rawData = processStreamData(rawData, setIsLoading, setAnswers, lastQuestion);
        }
    } catch (error) {
        setError(`Fail to fetch response`);
        console.error("Fetch error:", error.message);
    }
}

function processStreamData(rawData, setIsLoading, setAnswers, lastQuestion) {
    const lines = rawData.split("\n");
    for (let i = 0; i < lines.length - 1; i++) {
        const line = lines[i].trim();
        if (line) {
            setIsLoading(false);
            try {
                const parsedData = JSON.parse(line);
                processParsedData(parsedData, setAnswers, lastQuestion);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        }
    }
    // Return any incomplete data to be processed with the next chunk
    return lines[lines.length - 1];
}

function processParsedData(parsedData, setAnswers, lastQuestion) {
    setAnswers(prevAnswers => {
        // Check if the last entry in the previous answers array has a messageID or id.
        const hasId = entry => entry?.messageID || entry?.id;
        const lastAnswer = prevAnswers.length > 0 ? prevAnswers[prevAnswers.length - 1] : null;

        // If the last answer has an ID, we append the new data; otherwise, we replace the last entry.
        if (lastAnswer && hasId(lastAnswer[1])) {
            return [...prevAnswers, [lastQuestion, parsedData]];
        } else if (prevAnswers.length > 0) {
            return [...prevAnswers.slice(0, -1), [lastQuestion, parsedData]];
        } else {
            return [[lastQuestion, parsedData]];
        }
    });
}

export function checkResStatus(res, setError) {
    // const navigate = useNavigate();
    if (res.ok && res.status === 200) {
        return;
    } else if (res.status === 401) {
        Cookies.set("isUserLoggedIn", "False");
        window.location.replace(`${location.origin}/#/login`);
        setError("Please login to continue");
        throw new Error(apiUnauthorizedErrorMessage);
    } else if (res.status === 403) {
        window.location.replace(`${location.origin}/#/403`);
        setError("Please login to continue");
        throw new Error(apiUnauthorizedErrorMessage);
    } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        setError("fail to fetch response");
        throw new Error("Client side error");
    } else {
        setError("Server error");
        throw new Error("Server error");
    }
}

export function makeUseCaseShortQuestions(paramData) {
    // Destructure the paramData object to get FAQ fields and IconImages, with default values
    const { FAQ_1 = "", FAQ_2 = "", FAQ_3 = "", FAQ_4 = "", FAQ_5 = "", FAQ_6 = "", IconImages = {} } = paramData;

    // Define an array of tips to provide guidance
    const tips = [
        "Use clear and concise language to avoid confusion.",
        "Verify the information from reliable sources.",
        "State your assumptions to guide the model's response.",
        "Provide context to help the AI understand better.",
        "Be cautious of outdated information.",
        "Specify the format you want the response in."
    ];

    // Process the FAQ fields and return an array of objects
    return [FAQ_1, FAQ_2, FAQ_3, FAQ_4, FAQ_5, FAQ_6]
        .filter(field => field && field.trim() !== "") // Filter out empty or whitespace-only fields
        .map((field, i) => ({
            tip: tips[i] || "Use clear and concise language to avoid confusion.", // Use the corresponding tip or a default one if out of range
            text: field, // Set the text of the FAQ
            value: field, // Set the value of the FAQ (same as text)
            image: IconImages[i], // Use the corresponding image from IconImages
            type: "chat" // Set the type to "chat"
        }));
}

let timer = null;

/**
 * Starts the inactivity timer.
 */
export const startInactivityTimer = () => {
    timer = window.setTimeout(() => {
        if (Cookies.get("isUserLoggedIn") == "True") handleLogout();
    }, USER_INACTIVE_TIMEOUT);
};

/**
 * Clears the inactivity timer.
 */
export const clearInactivityTimer = () => {
    if (timer) {
        window.clearTimeout(timer);
    }
};

/**
 * Resets the inactivity timer.
 */
export const resetInactivityTimer = () => {
    clearInactivityTimer();
    startInactivityTimer();
};

export const checkUserLoggedIn = () => {
    const isUserLoggedIn = Cookies.get("isUserLoggedIn");
    if (isUserLoggedIn == "False") {
        window.location.replace(`${location.origin}/#/login`);
    } else {
        console.info("User is logged in");
    }
};

export const handleRemoveCosmosReference = async options => {
    const url = `/remove_document/`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options),
            credentials: "include"
        });
        await response.json();
    } catch (error) {
        console.error("error===>", error);
    }
};

export const removeSessionBlob = async options => {
    const url = `/remove_ci_session_files`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options),
            credentials: "include"
        });
        await response.json();
    } catch (error) {
        console.error("error===>", error);
    }
};
export const fetchWithRetry = async (url, options = {}, retries = 3) => {
    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(url, options);
            if (response.status === 403) {
                window.location.replace(`${location.origin}/#/login`);
            }
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            if (i === retries - 1) {
                throw error; // If it's the last retry, throw the error
            }
            console.warn(`Retrying... (${i + 1}/${retries})`);
        }
    }
};

export const isExternalLink = (string: string) => {
    const isMatch = !!string.match(EXTERNAL_LINK_REGEX) && !string.includes("content/");
    return isMatch;
};

export const makeValidUrl = (url: string): string => {
    // Regex to check if URL starts with http, https, or www, or contains a file extension
    // eslint-disable-next-line no-useless-escape
    const urlPattern = new RegExp(`^(https?:\/\/|www\.)|\.(?:${FILE_EXTENSIONS})(?=[?#]|$)`, "i");

    // If the URL doesn't match the pattern, prepend 'https://'
    if (!urlPattern.test(url)) {
        return `https://${url}`;
    }

    // Return the original URL if it's already valid
    return url;
};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
export const waitFunction = async (time = 1000) => {
    await delay(time); // Wait for 2 seconds
    return "Done"; // You can return any value you want
};

// Function to convert timestamp to CST
export const convertToCST = (timestamp: string): string => {
    return new Date(timestamp).toLocaleString("en-US");
};

export function getUniqueValues(arr) {
    return [...new Set(arr)];
}

export function checkRole(role, targetArray) {
    const uniqueRoles = [...new Set(targetArray)]; // Get unique values from the target array
    return roles.filter(role => uniqueRoles.includes(role)); // Filter roles that exist in the unique array
}

export function isValidGUID(guid) {
    const pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return pattern.test(guid);
}

export const sliderStyles = mergeStyles({
    selectors: {
        ".ms-Slider-thumb": {
            backgroundColor: "#0303b8" // Change thumb color
        },
        ".ms-Slider-track": {
            backgroundColor: "#0303b8" // Change track color
        },
        ".ms-Slider-activeTrack": {
            backgroundColor: "#0303b8" // Change active track color
        }
    }
});

export const slideBarStyle = {
    // root: { width: "200px" },
    activeSection: {
        background: "#0303b8",
        selectors: {
            ":hover": {
                backgroundColor: "#0303b8" // Change hover background color
            }
        }
    }
};

// Styles for the Toggle
export const toggleStyles = (isActive: boolean) => ({
    // root: { width: "150px" },
    pill: {
        backgroundColor: isActive ? "#0303b8" : "default",
        selectors: {
            ":hover": {
                backgroundColor: "#0303b8" // Change hover background color
            }
        }
    }
});

export const handleChunkStrategyParams = async (ChunkStrategyID: number) => {
    let options: DropdownItem[] = [];
    if (isLocalhost) {
        options = [
            { key: "Parameter1", text: "Parameter1" },
            { key: "Parameter2", text: "Parameter2" }
        ];
    } else {
        const result: { parameters: ChunkingStrategyParameters[]; success: boolean; message?: string } = await readChunkingStrategiesParametersItems(ChunkStrategyID);
        if (result?.success) {
            const parametersOptions = getDropdownOptions(result.parameters, "ParamName");
            options = parametersOptions || [];
        }
    }
    return options;
};

export const handleCleaningStrategyParams = async (CleaningID: number) => {
    let options: DropdownItem[] = [];
    if (isLocalhost) {
        options = [
            { key: "Parameter1", text: "Parameter1" },
            { key: "Parameter2", text: "Parameter2" }
        ];
    } else {
        const result: { parameters: ChunkingStrategyParameters[]; success: boolean; message?: string } = await readChunkingStrategiesParametersItems(CleaningID);
        if (result?.success) {
            const parametersOptions = getDropdownOptions(result.parameters, "ParamName");
            options = parametersOptions || [];
        }
    }
    return options;
};
export const handleExtractionParametersOptionsStrategyParams = async (ExtractionID: number) => {
    let options: DropdownItem[] = [];
    if (isLocalhost) {
        options = [
            { key: "Parameter1", text: "Parameter1" },
            { key: "Parameter2", text: "Parameter2" }
        ];
    } else {
        const result: { parameters: ChunkingStrategyParameters[]; success: boolean; message?: string } = await readExtractionStrategiesParamItems(ExtractionID);
        if (result?.success) {
            const parametersOptions = getDropdownOptions(result.parameters, "ParamName");
            options = parametersOptions || [];
        }
    }
    return options;
};

export const exportToJson = filteredItems => {
    const json = JSON.stringify(filteredItems, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    saveAs(blob, `${pathname}_Data_${new Date().toLocaleString()?.toUpperCase()?.split(" ")?.join("_")}.json`);
};

export const exportToCSV = filteredItems => {
    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredItems);
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    // Write the workbook to a CSV file
    XLSX.writeFile(workbook, `${pathname}_Data_${new Date().toLocaleString()?.toUpperCase()?.split(" ")?.join("_")}.csv`);
};

export const exportToExcel = filteredItems => {
    const worksheet = XLSX.utils.json_to_sheet(filteredItems);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, `${pathname}_Data_${new Date().toLocaleString()?.toUpperCase()?.split(" ")?.join("_")}.xlsx`);
};

export async function filterMenuItems(menuItems) {
    // Step 1: Find menus which have Order = 1 and Path = "#"
    const parentMenus = menuItems.filter(item => item.Order === 1 && item.Path === "#");

    // Create a dictionary mapping MenuItemID to menu item for quick lookup
    const menuDict = menuItems.reduce((acc, item) => {
        acc[item.MenuItemID] = item;
        return acc;
    }, {});

    // Result array to store the first child of each valid parent
    const result = [];

    // Step 2, 3, 4: Process each parent menu
    parentMenus.forEach(parent => {
        // Filter all child menus of the current parent menu
        const childMenus = menuItems.filter(item => item.ParentId === parent.MenuItemID);

        // Sort the child menus by Order (treating null as Int max) and MenuItemName alphabetically
        childMenus.sort((a, b) => {
            if (a.Order === null) a.Order = Number.MAX_SAFE_INTEGER;
            if (b.Order === null) b.Order = Number.MAX_SAFE_INTEGER;

            if (a.Order === b.Order) {
                return a.MenuItemName.localeCompare(b.MenuItemName);
            }
            return a.Order - b.Order;
        });

        // Step 4: Select the first item from the sorted list and add to the result
        if (childMenus.length > 0) {
            result.push(childMenus[0]);
        }
    });

    return result;
}

export function isSpecificFileType(fileName) {
    const allowedExtensions = [".pdf", ".pptx"];
    const fileExtension = fileName?.slice(fileName?.lastIndexOf("."))?.toLowerCase();
    return allowedExtensions.includes(fileExtension);
}

export const isValidJSON = (str: string) => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
};
