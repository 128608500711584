import axios from "axios";

import { handleError } from "../../menuSettings/menuManagementApis";

// Function to insert a new configurationAndSettings item
export const insertConfigurationAndSettingsItem = async (formData: FormData, id: number) => {
    try {
        const response = await axios.post(`/api/update_usecase/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a configurationAndSettings item
export const updateConfigurationAndSettingsItem = async (formData: FormData, id: number) => {
    try {
        const response = await axios.put(`/api/update_usecase/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to update a configurationAndSettings item
export const createConfigurationAndSettingsItem = async (formData: FormData) => {
    try {
        const response = await axios.post(`/api/create_usecase`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read configurationAndSettings items
export const readConfigurationAndSettingsItems = async (id: number) => {
    try {
        const response = await axios.get(`/api/usecase/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to read configurationAndSettings items
export const readAllUseCases = async () => {
    try {
        const response = await axios.get(`/api/usecases`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};

// Function to delete a configurationAndSettings item
export const deleteConfigurationAndSettingsItems = async (id: number | string) => {
    try {
        const response = await axios.delete(`/api/delete/${id}`);
        return response.data; // Return the response data
    } catch (error) {
        handleError(error);
    }
};
